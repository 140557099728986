<template>
  <b-card title="Edit GST">
    <!-- <b-col cols="12">
            <label>Name</label>
            <b-form-input placeholder="Name" v-model="form.name"></b-form-input>
        </b-col> -->

    <b-col cols="12">
      <label>
        Name
        <span style="color:red">(*)</span>
      </label>

      <b-form-input v-model="form.name"> </b-form-input>
    </b-col>

    <b-col cols="12">
      <label>Value(%)<span style="color:red">(*)</span></label>
      <b-form-input placeholder="Value(%)" v-model="form.value"></b-form-input>
    </b-col>

    <b-col cols="12">
        <label>Start Date<span style="color:red">(*)</span></label>
        <b-form-datepicker dropright placeholder="Select Date" v-model="form.startDate"></b-form-datepicker>
    </b-col>

    <b-col cols="12" class="mt-2">
      <b-button variant="success" @click="doUpdateGST"
        >Save</b-button
      >
    </b-col>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from '@/axios';

export default {
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    // this.getCategories();
    // this.$store.dispatch("GST/fetchGST");

    this.getGST(this.$route.params.id).then((data) => {
      this.form = data;
    });
  },
  methods: {
    ...mapActions({
      getGST: "GST/getGST",
      updateGST: "GST/updateGST",
      getCategories: "category/getCategories",
    }),
    doUpdateGST() {
        console.log('form',this.form)
      return this.updateGST(this.form)
        .then(() => {
          this.$bvToast.toast("Successfully update GST", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          let errorMessage = err.response.data.message;

          if (errorMessage !== undefined) {
            this.$bvToast.toast(errorMessage, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          } else {
            this.$bvToast.toast("Error Processing Data", {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
  },
  computed: {
    ...mapState({
      categories: (state) => {
        return state.category.categories.map((c) => ({
          value: c.id,
          text: c.name,
        }));
      },
    }),
  },
};
</script>
